import React from "react";
import {
    useGetGeneralContext,
    useUpdateGeneralContext
} from "../../../logic/context/general_context";
import {useTranslate} from "../../../logic/hooks/general_hook";
import {useNavigate, useParams} from "react-router-dom";

import Title from "../../custom/typography/title";
import Description from "../../custom/typography/description";
import CustomLink from "../../custom/action/customLink";
import {useGetUserInfo} from "../../../logic/hooks/question_hook";
import Loading from "../../custom/general/loading";

const Home = () => {
    const {dir, lang} = useGetGeneralContext();
    const {doUpdate} = useUpdateGeneralContext();
    const {t} = useTranslate();
    const {id} = useParams();
    const navigate = useNavigate();
    const {result, loading} = useGetUserInfo(id, {
        onError: ({error}) => {
            let msg = "";
            Object.values(error).map(err => {
                msg = `${err.enDesc} | ${err.faDesc}`
            })
            doUpdate({"error": msg})
            navigate(`/error`, {replace: true})
        }
    });

    return (
        <React.Fragment>
            {
                loading ? (<Loading small/>) : (
                    <div className={`row justify-content-center ${dir}`}>
                        <div className="col-12 col-md-10">
                            <Title className="text-main-color bold mb-3">{t("dear-guest", lang)}:</Title>
                            <Title
                                className="text-main-color bold mb-4">{result && result.customerInfo && result.customerInfo.customerName} {t("dear", lang)}</Title>
                            <Description className="text-main-color">
                                {t("start-text-1", lang)}
                                <br/>
                                {t("start-text-2", lang)}
                            </Description>
                            <div className="text-center mt-5">
                                <CustomLink className="btn-main-color px-4 mx-1"
                                            to={result && result.customerInfo ? `/q/${result.customerInfo.token}` : `/l/${id}`}>{t("start-survey", lang)}</CustomLink>
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default Home;

