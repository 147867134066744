import {useGetGeneralContext} from "../../../../logic/context/general_context";
import {useTranslate} from "../../../../logic/hooks/general_hook";

const Loading = ({small, title}) => {
    const {lang, dir} = useGetGeneralContext();
    const {t} = useTranslate();
    return (
        <div className={`text-center ${dir}`}>
            <div className={["spinner-border text-main-color", small && "spinner-border-sm"].join(" ")} role="status">
                <span className="sr-only"/>
            </div>
            <span className="mx-2">
                {
                    title || t("loading", lang)
                }
            </span>
        </div>
    )
}
export default Loading;