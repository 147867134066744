import _default from "./apiConfig.json";

export function getAuthHeader() {
    let token = localStorage.getItem(_default.token_name);
    token = JSON.parse(token);
    return {"Authorization": `Bearer ${token}`};
}

export function makeAuthHeader(type = '', info = _default) {
    let headerOption = {};
    if (type === '') {
        headerOption = {
            headers: getAuthHeader()
        };
    } else if (type === 'xlsx') {
        headerOption = {
            headers: getAuthHeader(),
            "responseType": "blob",
            'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
    } else {

    }

    return headerOption;
}
