import {Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';

import Home from "./components/pages/home";
import Header from "./components/layouts/header";
import GeneralContextProvider from "./logic/context/general_context";
import Questions from "./components/pages/questions";
import Lang from "./components/pages/lang";
import NotFound from "./components/custom/general/notFound";
import Thanks from "./components/pages/thanks";
import Init from "./components/layouts/init";
import {ToastContainer} from "react-toastify";
import Error from "./components/pages/error";

function App() {
    return (
        <GeneralContextProvider>
            <Init/>
            <div className="main-header-context">
                <Header/>
            </div>
            <div className="main-body-context">
                <div className="container">
                    <div className="row">
                        <Routes>
                            <Route exact path="/thanks/:id" element={<Thanks/>}/>
                            <Route exact path="/q/:id" element={<Questions/>}/>
                            <Route exact path="/welcome/:id" element={<Home/>}/>
                            <Route exact path="/l/:id" element={<Lang/>}/>
                            <Route exact path="/error" element={<Error/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </GeneralContextProvider>
    );
}

export default App;
