import React from "react";

const GeneralContext = React.createContext();
const GeneralSetContext = React.createContext();


function GeneralContextProvider({children}) {
    const [info, setInfo] = React.useState({
        lang: "fa",
        dir: "rtl",
        headerLogoStyle: "",
        error: ""
    });
    return (
        <GeneralContext.Provider value={info}>
            <GeneralSetContext.Provider value={setInfo}>
                {children}
            </GeneralSetContext.Provider>
        </GeneralContext.Provider>
    )
}

function useGetGeneralContext() {
    const info = React.useContext(GeneralContext);
    if (info === undefined) {
        throw new Error(
            "render Context at top of the tree"
        )
    }
    return info;
}

function useSetGeneralContext() {
    const setInfo = React.useContext(GeneralSetContext);
    if (setInfo === undefined) {
        throw new Error(
            "render Context at top of the tree"
        )
    }
    return setInfo;
}

function useUpdateGeneralContext() {
    const info = React.useContext(GeneralContext);
    const setInfo = React.useContext(GeneralSetContext);

    const doUpdate = (data) => {
        setInfo({...info, ...data});
    }

    return {doUpdate}
}

export {useGetGeneralContext, useSetGeneralContext, useUpdateGeneralContext}

export default GeneralContextProvider