import React from "react";
import faLang from "./../../assets/faLang.json"
import enLang from "./../../assets/enLang.json"

function useTranslate() {
    const t = (text, lang = "fa") => {
        switch (lang) {
            case "fa":
                return (faLang[text])
            case "en":
                return (enLang[text])
            default:
                return (faLang[text])
        }
    }

    return {t}

}

function useReloadDialog() {

    React.useEffect(() => {
        window.onbeforeunload = function (e) {
            e = e || window.event;

            // For IE and Firefox prior to version 4
            if (e) {
                e.returnValue = 'Sure?';
            }

            // For Safari
            return 'Sure?';
        }
        return () => {
            window.onbeforeunload = () => {
            }
        }
    }, [])

}

export {useTranslate, useReloadDialog}