import "./style.css";
import {
    useGetGeneralContext,
    useUpdateGeneralContext
} from "../../../../logic/context/general_context";
import {useTranslate} from "../../../../logic/hooks/general_hook";

const DescriptionQuestion = ({answerSetter, answer, info}) => {
    const {lang} = useGetGeneralContext();
    const {doUpdate} = useUpdateGeneralContext();
    const {t} = useTranslate();
    const setAnswer = (answer) => {
        answerSetter(answer);
    }

    const onBlur = () => {
        doUpdate({"headerLogoStyle": ""})
    }

    const onFocus = () => {
        doUpdate({"headerLogoStyle": "d-none"})
    }


    return (
        <div className="desc-question">
            <textarea className="form-control" rows="4"
                      onBlur={onBlur}
                      onFocus={onFocus}
                      maxLength={+(info.max_select)}
                      onChange={e => setAnswer(e.target.value)}
                      value={answer || ""}
                      placeholder={t("textarea-placeholder", lang)}/>
            <div className="desc-len text-end">
                <span className="small">{t("max-size", lang)}</span>
                <span className="small">
                    &nbsp;
                    {
                        info.max_select
                    }
                    &nbsp;
                    {
                        t("character", lang)
                    }
                </span>
            </div>
        </div>
    );
}

export default DescriptionQuestion;