import "./style.css";
import {useGetGeneralContext} from "../../../logic/context/general_context";
import {useTranslate} from "../../../logic/hooks/general_hook";

const Header = () => {
    const {dir, lang, headerLogoStyle} = useGetGeneralContext();
    const {t} = useTranslate();
    return (
        <div className="container-fluid p-0">
            <div className={`header ${dir}`}>
                <div className="container">
                    <div className={`row d-lg-flex ${headerLogoStyle}`}>
                        <div className="col">
                            <div className="logo">
                                <img src="/logo.svg" alt="logo"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="title">
                                <h6>
                                    {
                                        t("header-title", lang)
                                    }
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;