import React from "react";
import {useNavigate, useParams} from "react-router-dom"
import {useUpdateGeneralContext} from "../../../logic/context/general_context";
import config from "./../../../logic/statics/config.json"
import {useGetUserInfo} from "../../../logic/hooks/question_hook";
import Loading from "../../custom/general/loading";
import {toast} from "react-toastify";

const Lang = () => {
    const {doUpdate} = useUpdateGeneralContext();
    const navigate = useNavigate();
    const {id} = useParams();
    const changeLang = (lang) => {
        const changeObj = config.langOptions;
        doUpdate(changeObj[lang]);
        localStorage.setItem("lang", lang)
        navigate(`/welcome/${id}`);
    }

    const {loading} = useGetUserInfo(id, {
        onError: ({error}) => {
            let msg = "";
            Object.values(error).map(err => {
                msg = `${err.enDesc} | ${err.faDesc}`
            })
            doUpdate({"error": msg})
            navigate(`/error`, {replace: true})
        }
    })

    return (
        <div className="container">
            {
                loading ? (
                    <Loading small/>
                ) : (
                    <>
                        <div className="row rtl">
                            <div className="col">
                                <p className="text-center mb-2">لطفا زبان مورد نظر خود را انتخاب کنید</p>
                                <p className="text-center mb-5">Please select Language</p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-12 mb-4 text-center">
                                <button onClick={() => {
                                    changeLang("en")
                                }}
                                        className="btn btn-main-outline-color px-5">
                                    English
                                </button>
                            </div>
                            <div className="col-lg-3 col-12 mb-4 text-center">
                                <button onClick={() => changeLang("fa")}
                                        className="btn btn-main-outline-color px-5">
                                    فارسی
                                </button>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default Lang;