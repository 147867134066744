import {useEffect} from "react";
import {useUpdateGeneralContext} from "../../../logic/context/general_context";
import config from "./../../../logic/statics/config.json"


const Init = () => {
    const {doUpdate} = useUpdateGeneralContext()
    const langOptions = config.langOptions;
    const currentLangInLS = localStorage.getItem("lang");
    useEffect(() => {
        if (currentLangInLS && langOptions[currentLangInLS]) {
            doUpdate(langOptions[currentLangInLS])
        } else {
            doUpdate(langOptions["fa"])
            localStorage.setItem("lang", "fa")
        }
    }, [])
    return (
        <></>
    );
}

export default Init;