import "./style.css";
import {Link} from "react-router-dom";

const CustomLink = ({children, to, className}) => {
    return (
        <Link to={to || "/"} className={["btn custom-Link", className].join(" ")}>
            {children}
        </Link>
    )
}
export default CustomLink;