import React from "react";
import "./styles.css";
import {useGetGeneralContext} from "../../../../logic/context/general_context";

const Divider = (props) => {
    const {dir} = useGetGeneralContext();
    return (
        <div className={[props.className || "", "main-divider",dir].join(" ")}>
            <button
                onClick={() => (props.action ? props.action() : {})}
                className="btn divider-text"
            >
                {props.title}
            </button>
            {React.Children.map(props.children, function () {
                return props.children;
            })}
            <hr className="divider-line"/>
        </div>
    )
}
export default Divider;