import axios from "axios";
import {toast} from "react-toastify";
import _default from "./apiConfig.json";
import {getAuthHeader} from "./apiHeaderMaker";

axios.interceptors.request.use(
    function (config) {
        config.timeout = _default.apiTimeOut;
        if (config.changeApp && _default.api_base_url.hasOwnProperty(config.changeApp)) {
            config.url = _default.api_base_url[config.changeApp] + config.url;
        } else {
            config.url = _default.api_base_url['app1'] + config.url;
        }
        if (config.needAuth) {
            config.headers = {...config.headers, ...getAuthHeader()};
            delete config.needAuth;
        }
        return config;
    },
    function (error) {
        console.error("Api caller system Error");
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (res) => {
        let result = "";
        // status=200 handle + error handle
        if (res.data.status !== 200 && res.data.error) {
            const lang = localStorage.getItem("lang") || "fa";
            const errorLang = {
                "fa": "faDesc",
                "en": "enDesc"
            };
            Object.values(res.data.error).map(err => {
                if (err.showUser) {
                    let msg = err[errorLang[lang]];
                    toast.error(msg);
                }
            })
        }
        result = res.data;
        return result;
    },
    (error) => {
        if (error.message === "Network Error") {
            console.error("Network Error");
        }

        if (error.response && error.response.status) {
            if (error.response.status === 500) {
                // handle unAuth
                console.error("server error - 500");
            }

            if (error.response.status === 401) {
                // handle unAuth
                console.error("unAuth - 401");
                localStorage.removeItem(_default.token_name);
                window.location = "/";
                return
            }

            if (error.response.status === 403) {
                // handle forbidden
                console.error("forbidden - 403");
            }
        } else {
            console.error("not error response");
        }

        // ::- handle custom status code -::

        return Promise.reject(error);
    }
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};
