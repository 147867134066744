import {useHistory, useParams} from "react-router-dom";
import {useHandleSurvey} from "../../../logic/hooks/question_hook";
import Question from "../../custom/action/question";
import Divider from "../../custom/general/divider";
import React from "react";
import {useGetGeneralContext, useUpdateGeneralContext} from "../../../logic/context/general_context";
import {useReloadDialog, useTranslate} from "../../../logic/hooks/general_hook";
import Loading from "../../custom/general/loading";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const Questions = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslate();
    const {doUpdate} = useUpdateGeneralContext();
    const {
        result,
        loading,
        errors,
        currentQuestionId,
        answers,
        nextQuestion,
        prevQuestion,
        setAnswer,
        submit
    } = useHandleSurvey(id, {
        onError: ({error}) => {
            let msg = "";
            Object.values(error).map(err => {
                msg = `${err.enDesc} | ${err.faDesc}`
            })
            doUpdate({"error": msg})
            navigate(`/error`, {replace: true})
        }
    });
    const {lang, dir} = useGetGeneralContext();
    useReloadDialog();

    const setAnswerWithID = (answer) => {
        setAnswer(result[currentQuestionId].questionId, answer)
    }

    const onSubmitOpt = {
        onSuccess: () => {
            toast.success(t("success-survey", lang))
            navigate(`/thanks/${id}`)
        },
        onError: ({error}) => {
            let msg = "";
            Object.values(error).map(err => {
                msg = `${err.enDesc} | ${err.faDesc}`
            })
            doUpdate({"error": msg})
            navigate(`/error`, {replace: true})
        }
    };

    return (
        <div className="container">
            {loading ? (
                <>
                    <Loading small/>
                </>
            ) : (
                <>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <Divider className="mb-3" title={result[currentQuestionId][t("_title-type", lang)]}/>
                            <Question
                                id={currentQuestionId}
                                info={result[currentQuestionId]}
                                setAnswer={setAnswerWithID}
                                answers={answers}
                            />
                        </div>
                    </div>
                    <div className={`text-center ${dir}`}>
                        {
                            errors && errors[currentQuestionId] &&
                            <p className="text-danger">
                                {
                                    t("requirement", lang)
                                }
                            </p>
                        }
                    </div>
                    <div className={`row justify-content-center rtl`}>
                        <div className="col-lg-2 col-12 mb-4 text-end">
                            {
                                (currentQuestionId) !== ((result.length) - 1) ?
                                    (<button onClick={() => nextQuestion(result.length)}
                                             className="btn btn-main-color w-100 px-5">
                                        {
                                            t("next", lang)
                                        }
                                    </button>) : (
                                        <button onClick={() => submit(onSubmitOpt)}
                                                className="btn btn-main-color w-100 px-5">
                                            {
                                                t("submit", lang)
                                            }
                                        </button>
                                    )
                            }
                        </div>
                        <div className="col-lg-2 col-12 mb-4 text-start">
                            {
                                (currentQuestionId) !== 0 &&
                                <button onClick={() => prevQuestion(result.length)}
                                        className="btn btn-main-outline-color w-100 px-5">
                                    {
                                        t("prev", lang)
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Questions;