import {useGetGeneralContext} from "../../../../logic/context/general_context";
import "./style.css";
import {persianDigit} from "../../../../logic/core";
import React from "react";
import RangeQuestion from "../rangeQuestion";
import DescriptionQuestion from "../descriptionQuestion";
import {useTranslate} from "../../../../logic/hooks/general_hook";

const Question = ({info, id, setAnswer, answers}) => {
    const {lang, dir} = useGetGeneralContext();
    const {t} = useTranslate();
    const handleType = (type) => {
        switch (type) {
            case "rate":
                return (<RangeQuestion answer={answers[info.questionId]}
                                       info={info}
                                       answerSetter={setAnswer}/>)
            case "description":
                return (<DescriptionQuestion answer={answers[info.questionId.toString()]}
                                             info={info}
                                             answerSetter={setAnswer}/>)
        }
    }

    return (
        <div className={`question ${dir}`}>
            <p className="">
                <span>{persianDigit(id + 1,lang)}. </span>
                {info[t("_question-title", lang)]}
            </p>
            <div className="my-5 text-center">
                {
                    handleType(info.question_type)
                }
            </div>

        </div>
    )
}

export default Question;