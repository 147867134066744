export function persianDigit(string, lang = "fa") {
    if (lang === "fa") {
        const symbolMap = {
            1: "۱",
            2: "۲",
            3: "۳",
            4: "۴",
            5: "۵",
            6: "۶",
            7: "۷",
            8: "۸",
            9: "۹",
            0: "۰",
        };
        if (string) {
            string = string.toString();
            if (string.length > 0) {
                return (string
                    .replace(/\d/g, function (match) {
                        return symbolMap[match];
                    })
                    .replace(/,/g, "،"));
            } else {
                return string;
            }
        } else {
            return "";
        }
    } else {
        return string;
    }
}