import {useGetGeneralContext} from "../../../../logic/context/general_context";
import {useTranslate} from "../../../../logic/hooks/general_hook";

const NotFound = () => {
    const {lang, dir} = useGetGeneralContext();
    const {t} = useTranslate();
    return (
        <div className="container">
            <div className={`row ${dir}`}>
                <div className="col">
                    <p className="text-center">
                        {
                            t("404", lang)
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotFound;