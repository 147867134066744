import http from "./../apiEngine/apiCaller";

const options = {needAuth: true};

export async function get_customer_info(token) {
    if (token) {
        return await http.post(`/getCustomerInfo`, {token}, options);
    }
}

export async function get_survey_question(token) {
    return await http.post(`/getQuestions/survey`, {token}, options);
}

export async function set_survey_answers(token,answers) {
    return await http.post(`/setAnswers`, {token,answers}, options);
}


