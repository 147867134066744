import React from "react";
import {useTranslate} from "../../../logic/hooks/general_hook";
import {useGetGeneralContext} from "../../../logic/context/general_context";

const Error = () => {
    const {lang, dir, error} = useGetGeneralContext();
    const {t} = useTranslate();
    return (
        <div className={dir}>
            <p className="text-center text-main-color">
                {
                    error || t("error", lang)
                }
            </p>
        </div>
    )
}
export default Error;